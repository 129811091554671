import { ReactNode } from 'react';

import env from '@common/env';

import { client } from '../client/services.gen';

const DCBE_HOST = env('DCBE_HOST');
const ImpersonationSubjectIdentityProviderIdHeaderName = 'impersonationSubjectIdentityProviderId';
export default function DigitalCoreBeProvider({
  children,
  token,
  impersonationSubjectHeader,
}: {
  children: ReactNode;
  token: string | undefined;
  impersonationSubjectHeader: string | undefined;
}) {
  const headers: Record<string, unknown> = {
    Authorization: `Bearer ${token}`,
  };
  if (impersonationSubjectHeader) {
    headers[ImpersonationSubjectIdentityProviderIdHeaderName] = impersonationSubjectHeader;
  }
  client.setConfig({
    baseUrl: DCBE_HOST,
    headers,
  });

  return children;
}
