import Cookies from 'js-cookie';
import { signIn } from 'next-auth/react';

import logger from '@common/log';
import { useRouter } from '@dxp-next';
import {CrmAccountWithRole, Impersonation} from "types-be/next-auth";

import { useAccountCookie } from './useAccountCookie';
import { LS_KEY_ACCOUNTS,LS_KEY_IMPERSONATION, LS_KEY_SELECTED_ACCOUNT } from '../utils/auth/constants';


export default function useSelfServiceAccount() {
  const [accounts, setAccounts] = useAccountCookie<Array<CrmAccountWithRole>>(LS_KEY_ACCOUNTS);
  const [selectedAccount, setSelectedAccount] = useAccountCookie<CrmAccountWithRole>(LS_KEY_SELECTED_ACCOUNT);
  const router = useRouter();
  const [impersonation, setImpersonation] = useAccountCookie<Impersonation>(LS_KEY_IMPERSONATION);

  const switchAccount = (crmAccountNumber: CrmAccountWithRole['crmAccountNumber'], route?: string) => {
    const accountToSelect = accounts?.find(account => account.crmAccountNumber === crmAccountNumber);
    if (accountToSelect) {
      setSelectedAccount(accountToSelect);
      if (!route) {
        router.pushHref(router.activePath);
      } else {
        router.pushHref(route);
      }
    } else {
      logger.error('dD3FXH', `Selected account doesn't exist`);
    }
  };

  const isCurrentAccountReader = selectedAccount?.role === 'reader' || impersonation?.role === 'reader';
  const isCurrentAccountEditor = selectedAccount?.role === 'editor' || impersonation?.role === 'editor';
  const isCurrentAccountOwner = selectedAccount?.role === 'owner' || impersonation?.role === 'editor';

  const refreshAccounts = (callbackUrl?: string) => {
    signIn('okta', { callbackUrl });
    if (accounts.length > 0) {
      setSelectedAccount(accounts[0]);
    } else {
      Cookies.remove(LS_KEY_SELECTED_ACCOUNT);
    }
  };
  const impersonate = (impersonation: Impersonation) => {
    setImpersonation(impersonation);
  };
  const isImpersonating = () => impersonation?.type !== 'None'

  /**
   * This function is used to set the selected account without any checks to see
   * if the user has access to the account.
   * @param account the account to set as selected
   */
  const unsafelySetSelectedAccount = (account: CrmAccountWithRole) => {
    setSelectedAccount(account);
  };

  return {
    selectedAccount,
    accounts,
    refreshAccounts,
    switchAccount,
    setAccounts,
    unsafelySetSelectedAccount,
    isCurrentAccountReader,
    isCurrentAccountOwner,
    isCurrentAccountEditor,
    impersonate,
    impersonation,
    isImpersonating
  };
}
