import { useContext } from 'react';

import Cookies from 'js-cookie';

import logger from '@common/log';
import { AccountsContext } from '@dxp-next-be/accountsProvider';
import { CrmAccountWithRole, Impersonation } from 'types-be/next-auth';

import { LS_KEY_ACCOUNTS, LS_KEY_IMPERSONATION, LS_KEY_SELECTED_ACCOUNT } from '../utils/auth/constants';

export function useAccountCookie<T extends CrmAccountWithRole | CrmAccountWithRole[] | Impersonation | null>(
  key: typeof LS_KEY_SELECTED_ACCOUNT | typeof LS_KEY_ACCOUNTS | typeof LS_KEY_IMPERSONATION,
) {
  const { accounts, selectedAccount, setSelectedAccount, impersonation } = useContext(AccountsContext);

  let storedValue: CrmAccountWithRole[] | CrmAccountWithRole | Impersonation | null;
  if (key === LS_KEY_ACCOUNTS) {
    storedValue = accounts;
  } else if (key === LS_KEY_SELECTED_ACCOUNT) {
    storedValue = selectedAccount;
  } else {
    storedValue = impersonation;
  }

  const setValue = (value: T) => {
    try {
      // Save to cookie
      if (key === LS_KEY_SELECTED_ACCOUNT) setSelectedAccount(value as CrmAccountWithRole);
      Cookies.set(key, JSON.stringify(value));
    } catch (error) {
      if (error) logger.error('tJ5N8E', error.toString());
    }
  };

  return [storedValue as T, setValue] as const;
}
