import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react';

import { CrmAccountWithRole, Impersonation } from 'types-be/next-auth';

export interface AccountsContextProps {
  selectedAccount: CrmAccountWithRole;
  accounts: CrmAccountWithRole[];
  setSelectedAccount: Dispatch<SetStateAction<CrmAccountWithRole>>;
  impersonation: Impersonation;
}

export const AccountsContext = createContext<AccountsContextProps>({
  selectedAccount: {
    alias: '',
    crmAccountNumber: '',
    role: 'owner',
  },
  accounts: [],
  setSelectedAccount: () => undefined,
  impersonation: {
    actsAsIdentityProviderDisplayName: '',
    actsAsIdentityProviderId: '',
    impersonatorSubject: '',
    accounts: [],
    type: 'None',
    role: "reader"
  }
});

interface AccountsProviderProps extends PropsWithChildren {
  selectedAccount: CrmAccountWithRole;
  accounts: CrmAccountWithRole[];
  impersonation: Impersonation;
}

export const AccountsProvider: FC<AccountsProviderProps> = ({
  children,
  selectedAccount: selectedAccountDefault,
  accounts,
  impersonation
}) => {
  const [selectedAccount, setSelectedAccount] = useState<CrmAccountWithRole>(selectedAccountDefault);

  return (
    <AccountsContext.Provider value={{ selectedAccount, accounts, setSelectedAccount, impersonation }}>
      {children}
    </AccountsContext.Provider>
  );
};
