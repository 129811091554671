import React, { FC } from 'react';

import { useFormatter } from '@i18n';
import { Stack, Text, TextLink } from '@sparky';
import { CrmAccountWithRole } from 'types-be/next-auth';

import useSelfServiceAccount from '../../hooks/useSelfServiceAccount';
import logout from '../../utils/auth/logout';

interface ImpersonationFields {
  loggedInAsText: string;
  logoutText: string;
}

const fields: ImpersonationFields = {
  loggedInAsText: 'Logged in as: {alias} - {value}',
  logoutText: 'Log out',
};

const TopBarText = ({
  format,
  selectedAccount,
}: {
  format: (message: string, context: MessageContext) => string;
  selectedAccount: CrmAccountWithRole;
}) => (
  <>
    {format(fields.loggedInAsText, {
      alias: (selectedAccount.alias as string) ?? '',
      value: selectedAccount.crmAccountNumber,
    })}
  </>
);

const ImpersonationBanner: FC<React.PropsWithChildren> = ({ children }) => {
  const { selectedAccount, isImpersonating } = useSelfServiceAccount();
  const { format } = useFormatter();

  if (!isImpersonating()) {
    return children;
  }
  return (<>
        <Stack direction={{ initial: 'row' }} gap="8">
          <Text color={'textBrand'} weight={'bold'}>
            <TopBarText format={format} selectedAccount={selectedAccount} />
          </Text>
          <TextLink color={'textBrand'} emphasis="low" onClick={() => logout(true)}>
            {fields.logoutText}
          </TextLink>
        </Stack>
      {children}
    </>
  );
};

export default ImpersonationBanner;
